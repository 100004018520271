import React from "react";
import { Route, Routes } from "react-router-dom";

import LoadingSpinner from "../components/shared/widgets/LoadingSpinner";

const Home = React.lazy(() => import("../components/Pages/Home"));
const Contact = React.lazy(() => import("../components/Pages/Contact"));
const About = React.lazy(() => import("../components/Pages/AboutUs"));
const Products = React.lazy(() => import("../components/Pages/Products"));
const ProductPage = React.lazy(() => import("../components/Pages/ProductPage"));

const RoutingComponent = () => {
  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/product-details/:name" element={<ProductPage />} />
        <Route exact path="/produce" element={<Products />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </React.Suspense>
  );
};

export default RoutingComponent;
