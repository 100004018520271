import React from "react";
import { Icon } from "@iconify/react";
import { NavLink } from "react-router-dom";
import { contactInformation } from "../../utilities/contact";

const Footer = () => {
  return (
    <footer>
      <div className="footer-top bg-primary">
        <div className="contact-details">
          <img src="/assets/purefresh-logo.png" alt="PureExoticsFresh" />
          <div className="contacts-container">
            {contactInformation.map((_contact) => (
              <a href={_contact.link || ""} key={_contact.name}>
                <Icon icon={_contact.icon} fontSize={"19"} className="" />
                <span>{_contact.info}</span>
              </a>
            ))}
          </div>
        </div>
        <div className="links">
          <p className="quick-links">Quick Links</p>
          <div>
            <NavLink
              to="/"
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending-footer-link"
                  : isActive
                  ? "active-footer-link"
                  : ""
              }
            >
              Home
            </NavLink>
            <NavLink
              to="/produce"
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending-footer-link"
                  : isActive
                  ? "active-footer-link"
                  : ""
              }
            >
              Products
            </NavLink>
            <NavLink
              to="/contact-us"
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending-footer-link"
                  : isActive
                  ? "active-footer-link"
                  : ""
              }
            >
              Contact Us
            </NavLink>
            <NavLink
              to="/about-us"
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending-footer-link"
                  : isActive
                  ? "active-footer-link"
                  : ""
              }
            >
              About Us
            </NavLink>
          </div>
        </div>
      </div>
      <div className="footer-bottom bg-secondary">
        <p>&copy; Pure Exotics Fresh Ltd. {new Date().getFullYear()}. </p>
        <a target="_blank" rel="noreferrer" href="https://nawiribrand.africa">
          <p>Developed by Nawiribrand</p>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
