import "./App.css";
import "./components/shared/shared.css";
import Navbar from "./components/shared/Navbar";
import Footer from "./components/shared/Footer";
import RoutingComponent from "./routes/router";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

function App() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const toggleDropdown = () => {
    document.querySelector("#app-mask").classList.toggle("hide");
    setShowDropdown(!showDropdown);
  };

  const pageClickActions = () => {
    if (showDropdown) toggleDropdown();
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <div className="app">
      <Navbar
        showDropdown={showDropdown}
        toggleDropdown={toggleDropdown}
        className={location.pathname === "/" ? "home-nav" : ""}
      />
      <div id="app-mask" className="hide" onClick={pageClickActions}></div>
      <div className="app-content">
        <RoutingComponent />
      </div>
      <Footer />
    </div>
  );
}

export default App;
