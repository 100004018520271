import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import { Link, NavLink } from "react-router-dom";

const Dropdown = ({ toggleDropdown }) => {
  return (
    <nav className="dropdown-nav">
      <div className="nav-items">
        <NavLink
          to="/"
          onClick={toggleDropdown}
          className={({ isActive, isPending }) => {
            return isPending ? "pending" : isActive ? "active-nav-link" : "";
          }}
        >
          Home
        </NavLink>
        <NavLink
          to="/produce"
          onClick={toggleDropdown}
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active-nav-link" : ""
          }
        >
          Our Products
        </NavLink>
        <NavLink
          to="/about-us"
          onClick={toggleDropdown}
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active-nav-link" : ""
          }
        >
          About Us
        </NavLink>
      </div>
      <NavLink
        className="btn nav-btn bg-secondary"
        to="/contact-us"
        onClick={toggleDropdown}
      >
        Contact Us
      </NavLink>
    </nav>
  );
};

const Navbar = ({ className, showDropdown, toggleDropdown }) => {
  return (
    <header className={"nav-container " + className}>
      <Link to="/" className="brand">
        <img src="/assets/purefresh-logo.png" alt="pureExotics" />
        <div>
          <h1 className="text-primary">Pure Exotics Fresh</h1>
          <p>Fresh Just For You!</p>
        </div>
      </Link>
      <nav className="topbar-nav">
        <div className="nav-items">
          <NavLink
            to="/"
            className={({ isActive, isPending }) => {
              return isPending ? "pending" : isActive ? "active-nav-link" : "";
            }}
          >
            Home
          </NavLink>
          <NavLink
            to="/produce"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active-nav-link" : ""
            }
          >
            Our Products
          </NavLink>
          <NavLink
            to="/about-us"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active-nav-link" : ""
            }
          >
            About Us
          </NavLink>
        </div>

        <NavLink className="btn nav-btn bg-secondary" to="/contact-us">
          Contact Us
        </NavLink>
      </nav>
      <button
        className="btn text-primary nav-toggle-btn"
        onClick={toggleDropdown}
      >
        <Icon icon="mdi:microsoft-xbox-controller-menu" fontSize={"28"} />
      </button>
      {showDropdown ? <Dropdown toggleDropdown={toggleDropdown} /> : null}
    </header>
  );
};

export default Navbar;
