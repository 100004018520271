export const contactInformation = [
  {
    name: "phone",
    info: "+254759856356 / +254722288732",
    icon: "mdi:telephone",
    link: "tel:+254759856356",
  },
  {
    name: "whatsapp",
    info: "+254759856356",
    icon: "ic:baseline-whatsapp",
    link: "https://api.whatsapp.com/send?phone=2547598563563&text=%20",
  },
  {
    name: "email",
    info: "marketing@pureexoticsfresh.com",
    icon: "mdi:email-outline",
    link: "mailto:marketing@pureexoticsfresh.com",
  },
  {
    name: "location",
    info: "Old North Airport Road, Nairobi, Kenya",
    icon: "mdi:location",
    link: "",
  },
];
